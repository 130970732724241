.btn, .button, #{$all-buttons} {
	appearance: none;
	outline: none;
	background-color: $action-color;
	border: 2px solid white;
	border-radius: $base-border-radius;
	color: white;
	cursor: pointer;
	display: inline-block;
	font-family: $base-font-family;
	font-size: $base-font-size;
	font-weight: 900;
	-webkit-font-smoothing: antialiased;
	padding: 0.25em 1em;
	text-align: center;
	text-decoration: none;
	transition: all $base-duration $base-timing;
	user-select: none;
	vertical-align: middle;
	// white-space: nowrap
	box-shadow: 0px 0px 5px 0px rgba(black, 0.3);

	&:hover,
	&:focus {
		background-color: $brand-color-2;
		text-decoration: none;
		color: white; }

	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;

		&:hover {
			background-color: $action-color; } }

	// additional, alternate colors
	&.alt {
		background-color: white;
		color: $brand-color-1;
		box-shadow: none;
		border-color: #d0c3bb;
		&:hover {
			background: tint($brand-color-2, 85%); } }

	&.cta {
		margin: 1em auto;
		z-index: 4;
		position: relative;
		font-size: em(24); } }
