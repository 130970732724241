// Modal Styles
body.active-modal {
	// force overflow hidden on wrapper element when modal is active
	// iOS doesn't honor overflow hidden on body element
	#wrapper {
		overflow: hidden;
		max-height: 100%; } }

.modal {
	display: none;
	color: white;
	@include headings() {
		color: white; } }

// using jquery modal: https://github.com/kylefox/jquery-modal
.blocker {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
	z-index: 10;
	padding: $large-spacing $small-spacing;
	background-color: rgba(darken($brand-color-1, 25%),0.95);
	text-align: center;

	&:before {
		content: "";
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-right: -0.05em; }

	&.behind {
		background-color: transparent; }

	.modal {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		z-index: 20;
		width: 100%;
		max-width: 100vw;
		text-align: left;
		// background: #fff
		// border-radius: 8px
		// box-shadow: 0 0 10px #000

		&.alert {
			max-width: 900px;
			background-color: $brand-color-1;
			min-height: auto;
			text-align: center;
			border-radius: $base-border-radius;
			padding: $base-spacing;
			border: 2px solid white; }

		a.close-modal {
			position: fixed;
			top: 20px;
			right: 20px;
			display: block;
			width: 30px;
			height: 30px;
			text-indent: -9999px;
			z-index: 50;
			color: white;
			// background-color: white
			// border-radius: 50%
			// border: 2px solid white
			text-align: center;
			&:before {
				content: "\f057";
				font-family: FontAwesome;
				text-indent: 0;
				font-size: 30px;
				line-height: 0.88;
				display: block;
				position: absolute;
				top: 0;
				left: 0.5px;
				width: 100%; }
			&:hover:before {
				color: tint($brand-color-1, 50%); } } }

	.modal-spinner {
		display: none;
		width: 64px;
		height: 64px;
		position: fixed;
		top: 50%;
		left: 50%;
		margin-right: -32px;
		margin-top: -32px;
		border-radius: 8px; }

	@media screen and (min-width: $small-screen) {
		padding: $large-spacing; } }
