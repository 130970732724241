// Breakpoints
$small-screen: 480px;
$medium-screen: 768px;
$large-screen: 1024px;
$x-large-screen: 1300px;

// Typography
// $helvetica and $georgia are defined in node_modules/bourbon/app/assets/stylesheets/addons/_font-stacks.scss
$base-font-family: 'Avenir W01', $helvetica;
$heading-font-family: $base-font-family;
$serif-font-family: $georgia;
$mono-font-family: $monospace;

// Font Sizes
$em-base: 16px; // default: 16px
$base-font-size: $em-base;
$base-font-weight: 400;

$h1-font-size: em(25);
$h2-font-size: em(24);
$h3-font-size: em(22);
$h4-font-size: em(21);
$h5-font-size: em(20);
$h6-font-size: em(18);

// Line height
$base-line-height: 1.4;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 6px;
$base-spacing: $base-line-height * 1em;
$large-spacing: $base-spacing * 2;
$small-spacing: $base-spacing / 2;
$tiny-spacing: $base-spacing / 3;
$base-z-index: 0;
$base-side-padding: 40px;

// Colors
$base-color: #333; // charcoal
$brand-color-1: #a78f82;
// what percent gets close to #f2e6d5?
$brand-color-2: #72726c;
// what percent gets close to #dbdbda?

// Font Colors
$base-font-color: $base-color;
$action-color: $brand-color-1;
$action-color-2: #f2e6d5;

// Border
$base-border-color: #ccc;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;

// ****
// Neat grid settings:
// ****
// @import "neat-helpers"

// Display grid in background
// $visual-grid: true

// Change the grid settings
$max-width: 90%;

$modular-scale-ratio: $golden;
// $modular-scale-base: em($em-base) !default

// ------------------------------------------------------------
// Override Neat's grid settings
// ------------------------------------------------------------
$column: modular-scale(3); // Column width
$gutter: modular-scale(3); // Gutter between each two columns

// ------------------------------------------------------------
// Set Bourbon's flex-grid variables to match what Neat is doing
// http://bourbon.io/docs/#flex-grid
// ------------------------------------------------------------
$fg-column: $column;
$fg-gutter: $gutter;

// Define your breakpoints
$tablet: new-breakpoint(max-width $medium-screen 8);
$mobile: new-breakpoint(max-width $small-screen 4);
