form {
	// border: 1px solid rgba($brand-color-1, 0.2)
	// border-radius: 6px
	// padding: 15px
	text-align: left;
	width: 100%; }

fieldset {
	background-color: transparent;
	border: 0;
	margin: 0;
	padding: 0; }

legend {
	font-weight: 600;
	margin-bottom: $small-spacing / 2;
	padding: 0; }

label {
	display: block;
	font-weight: 300;
	margin-bottom: $small-spacing / 2;
	color: $base-color; }

#{$all-text-inputs} {
	appearance: none;
	background-color: $base-background-color;
	border: $base-border;
	border-radius: $base-border-radius;
	box-shadow: $form-box-shadow;
	box-sizing: border-box;
	margin-bottom: $small-spacing;
	padding: $base-spacing / 3;
	transition: border-color $base-duration $base-timing;
	width: 100%;
	line-height: 1;

	&:hover {
		border-color: shade($base-border-color, 20%); }

	&:focus {
		border-color: $action-color;
		box-shadow: $form-box-shadow-focus;
		outline: none; }

	&:disabled {
		background-color: shade($base-background-color, 5%);
		cursor: not-allowed;

		&:hover {
			border: $base-border; } }

	&::placeholder {
		color: tint($base-font-color, 40%); } }

input,
select,
textarea {
	display: block;
	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: $base-line-height; }

// input[type=text], input[type=email], input[type=password], textarea, select
// 	// font-size: 1.1em
// 	// padding: .25em .5em
// 	// color: #666
// 	// outline: 0
// 	// width: 100%
/// margin-top: .5em


textarea {
	resize: vertical; }

[type="checkbox"],
[type="radio"] {
	display: inline;
	margin-right: $small-spacing / 2; }

[type="file"] {
	margin-bottom: $small-spacing;
	width: 100%; }

// select
// 	margin-bottom: $small-spacing
// 	width: 100%

// EE form classes

.dform_element {
 }	// margin-bottom: 1em

.dfcolumns {
	position: relative;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.column {
		flex: 0 0 100%;
		position: relative;
		margin: 0 auto; } }

@media screen and (min-width: $small-screen) {
	.dform_columns_2 {
		.dfcolumns {
			.column {
				flex: 0 0 48%; } } } }

@media screen and (min-width: $small-screen) {
	.dform_columns_3 {
		.dfcolumns {
			.column {
				flex: 0 0 32%; } } } }


.dform_error {
	display: inline-block;
	margin-left: 1em;
	background: red;
	color: white;
	padding: .25em .5em;
	font-weight: 900; }

