* {
	box-sizing: border-box; }

*,
*::before,
*::after {
	box-sizing: inherit; }

html,
body {
	height: 100%; }

// This only works with JavaScript,
// if it's not present, don't show loader
.no-js #loader {
	display: none; }
.js #loader {
	display: block; }
#loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: white; }

/* BLOCKIZE THE SEMANTICS */
sticky,wrapper,logo,banner,videoBox,clear,section,row,column,box,content,main,nav,menu {
	display: block; }

/* bourbon clearfix */
.cf, form, .dfcolumns, clear {
	@include clearfix; }

clear {
	width: 0;
	height: 0;
	clear: both; }

vertical {
	display: table-cell;
	height: 100%;
	width: 100%;
	vertical-align: middle; }

.left {
	float: left; }
.right {
	float: right; }


// wrapping element, just inside the body tag
body > wrapper {
	// adjust width of the centered wrapper.
	@include outer-container(100%);
	position: relative;

	> header {
		text-align: center;
		position: relative;

		nav {
			@include outer-container(100%); }

		logo {
			position: relative;
			background-color: white;
			// width: 310px
			display: block;
			margin: 0 auto;
			height: 150px;
			padding: 1em 5%;

			a {
				display: block;
				position: relative;
				height: 100%;
				img {
					display: block;
					height: 100%;
					margin: 0 auto; } }

			@media screen and (orientation: landscape) and (max-height: 400px) {
				height: 100px; } } }


	content {
		// max-width: 1100px
		@include outer-container();
		@include clearfix;
		margin: 0 auto;
		position: relative;
		&#main {
			// max-width: $large-screen
			text-align: justify;

			h3 {
				margin-bottom: 1em; }
			#intro {
				text-align: center;
				margin-bottom: 2em;
				p {
					font-size: em(17);
					line-height: 1.6; } } }

		@media screen and (min-width: $x-large-screen) {
			max-width: 1200px; } }

	> footer {
		margin-top: 2em;
		h6 {
			color: $brand-color-2;
			margin-bottom: 1em;
			font-size: em(16); }

		wrapper {
			background-color: $brand-color-2;
			color: #dbdbda;
			padding: 1em 0;
			background-image: image('logo-icon.svg');
			background-repeat: no-repeat;
			background-position: bottom center;
			background-size: auto 140px;
			background-opacity: 0.5;
			a {
				color: $action-color-2; }
			content {
				&.info {
					p {
						font-size: em(17);
						font-weight: 500;
						margin-bottom: .5em;
						a {
							font-weight: 800;
							font-color: $action-color-2;
							font-size: em(18, 17); } }
					.phone {
						color: white;
						font-size: em(26);
						font-weight: 800; } }
				&.social-buttons span {
					border-radius: 50%;
					background: $action-color-2;
					padding: .5em;
					display: inline-block;
					line-height: 1;
					margin-right: .5em;
					&:last-child {
						margin-right: 0; }
					&:hover {
						background: white; }
					a {
						color: $brand-color-2; } }
				&.post_footer {
					margin-top: 120px;
					text-align: center;
					p {
						font-size: em(11);
 }						// width: 48%
					a {
						padding: 0 .5em;
						margin: 0 -2px 0 0;
						border-right: 1px solid #dbdbda;
						&.last {
							border-right: none;
							padding-right: 0;
							margin-right: 0; } } } } }

		@media screen and (min-width: $small-screen) {
			margin-top: 3em;
			wrapper content {
				&.post_footer {
					display: flex;
					justify-content: space-between;
					p {
						flex: 0 0 49%; } } } } } }


