$nested-list-margin: 0.5em 0 0.75em;
$list-line-height: 1.3;

ul,
ol {
	list-style-type: none;
	margin: 0;
	padding: 0; }

ul {
	list-style-type: disc;
	padding-left: 1.5em;
	li {
		position: relative;
		// &:before
		// 	content: "\f111"
		// 	font-family: FontAwesome
		// 	font-size: 0.75em
		// 	display: inline
		// 	position: absolute
		// 	left: -1.5em
 } }		// 	top: 0.25em

ol {
	list-style-type: decimal;
	padding-left: 3em;
	ol {
		list-style-type: lower-alpha;
		padding-left: 1.5em; } }

dl, dt, dd {
	margin: 0; }
dt {
	font-weight: 600; }
