@charset "UTF-8";
* {
  box-sizing: border-box; }

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, code, img, strike, strong, sub, sup,
fieldset, figcaption, figure, form, label, q,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, figure, figcaption, footer, header,
menu, nav, section, summary, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  outline: none; }

body {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

a {
  text-decoration: none;
  color: inherit; }
  a img {
    border: none; }

main, figcaption, figure {
  display: block; }

table, th, tbody, tr, td {
  border: 0; }

input[type=text], input[type=email], input[type=number], input[type=password], textarea {
  padding: 5px 10px;
  border: 1px solid #999;
  background: none;
  font: inherit;
  letter-spacing: inherit; }
  input[type=text]:focus, input[type=email]:focus, input[type=number]:focus, input[type=password]:focus, textarea:focus {
    border-color: black; }

button, input[type=submit] {
  font: inherit; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

.btn, .button, button, input[type="button"], input[type="reset"], input[type="submit"] {
  appearance: none;
  outline: none;
  background-color: #a78f82;
  border: 2px solid white;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "Avenir W01", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  padding: 0.25em 1em;
  text-align: center;
  text-decoration: none;
  transition: all 150ms ease;
  user-select: none;
  vertical-align: middle;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3); }
  .btn:hover, .btn:focus, .button:hover, .button:focus, button:hover, button:focus, input[type="button"]:hover, input[type="button"]:focus, input[type="reset"]:hover, input[type="reset"]:focus, input[type="submit"]:hover, input[type="submit"]:focus {
    background-color: #72726c;
    text-decoration: none;
    color: white; }
  .btn:disabled, .button:disabled, button:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, input[type="submit"]:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    .btn:disabled:hover, .button:disabled:hover, button:disabled:hover, input[type="button"]:disabled:hover, input[type="reset"]:disabled:hover, input[type="submit"]:disabled:hover {
      background-color: #a78f82; }
  .btn.alt, .button.alt, button.alt, input[type="button"].alt, input[type="reset"].alt, input[type="submit"].alt {
    background-color: white;
    color: #a78f82;
    box-shadow: none;
    border-color: #d0c3bb; }
    .btn.alt:hover, .button.alt:hover, button.alt:hover, input[type="button"].alt:hover, input[type="reset"].alt:hover, input[type="submit"].alt:hover {
      background: #eaeae9; }
  .btn.cta, .button.cta, button.cta, input[type="button"].cta, input[type="reset"].cta, input[type="submit"].cta {
    margin: 1em auto;
    z-index: 4;
    position: relative;
    font-size: 1.5em; }

form {
  text-align: left;
  width: 100%; }

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  font-weight: 600;
  margin-bottom: 0.35em;
  padding: 0; }

label {
  display: block;
  font-weight: 300;
  margin-bottom: 0.35em;
  color: #333; }

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea {
  appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-bottom: 0.7em;
  padding: 0.46667em;
  transition: border-color 150ms ease;
  width: 100%;
  line-height: 1; }
  input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, input:not([type]):hover, textarea:hover {
    border-color: #a3a3a3; }
  input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, input:not([type]):focus, textarea:focus {
    border-color: #a78f82;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(156, 130, 115, 0.7);
    outline: none; }
  input[type="color"]:disabled, input[type="date"]:disabled, input[type="datetime"]:disabled, input[type="datetime-local"]:disabled, input[type="email"]:disabled, input[type="month"]:disabled, input[type="number"]:disabled, input[type="password"]:disabled, input[type="search"]:disabled, input[type="tel"]:disabled, input[type="text"]:disabled, input[type="time"]:disabled, input[type="url"]:disabled, input[type="week"]:disabled, input:not([type]):disabled, textarea:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed; }
    input[type="color"]:disabled:hover, input[type="date"]:disabled:hover, input[type="datetime"]:disabled:hover, input[type="datetime-local"]:disabled:hover, input[type="email"]:disabled:hover, input[type="month"]:disabled:hover, input[type="number"]:disabled:hover, input[type="password"]:disabled:hover, input[type="search"]:disabled:hover, input[type="tel"]:disabled:hover, input[type="text"]:disabled:hover, input[type="time"]:disabled:hover, input[type="url"]:disabled:hover, input[type="week"]:disabled:hover, input:not([type]):disabled:hover, textarea:disabled:hover {
      border: 1px solid #ccc; }
  input[type="color"]::placeholder, input[type="date"]::placeholder, input[type="datetime"]::placeholder, input[type="datetime-local"]::placeholder, input[type="email"]::placeholder, input[type="month"]::placeholder, input[type="number"]::placeholder, input[type="password"]::placeholder, input[type="search"]::placeholder, input[type="tel"]::placeholder, input[type="text"]::placeholder, input[type="time"]::placeholder, input[type="url"]::placeholder, input[type="week"]::placeholder, input:not([type])::placeholder, textarea::placeholder {
    color: #858585; }

input,
select,
textarea {
  display: block;
  font-family: "Avenir W01", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 16px;
  line-height: 1.4; }

textarea {
  resize: vertical; }

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: 0.35em; }

[type="file"] {
  margin-bottom: 0.7em;
  width: 100%; }

.dfcolumns {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .dfcolumns .column {
    flex: 0 0 100%;
    position: relative;
    margin: 0 auto; }

@media screen and (min-width: 480px) {
  .dform_columns_2 .dfcolumns .column {
    flex: 0 0 48%; } }

@media screen and (min-width: 480px) {
  .dform_columns_3 .dfcolumns .column {
    flex: 0 0 32%; } }

.dform_error {
  display: inline-block;
  margin-left: 1em;
  background: red;
  color: white;
  padding: .25em .5em;
  font-weight: 900; }

* {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

html,
body {
  height: 100%; }

.no-js #loader {
  display: none; }

.js #loader {
  display: block; }

#loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: white; }

/* BLOCKIZE THE SEMANTICS */
sticky, wrapper, logo, banner, videoBox, clear, section, row, column, box, content, main, nav, menu {
  display: block; }

/* bourbon clearfix */
.cf::after, form::after, .dfcolumns::after, clear::after {
  clear: both;
  content: "";
  display: block; }

clear {
  width: 0;
  height: 0;
  clear: both; }

vertical {
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle; }

.left {
  float: left; }

.right {
  float: right; }

body > wrapper {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  body > wrapper::after {
    clear: both;
    content: "";
    display: block; }
  body > wrapper > header {
    text-align: center;
    position: relative; }
    body > wrapper > header nav {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto; }
      body > wrapper > header nav::after {
        clear: both;
        content: "";
        display: block; }
    body > wrapper > header logo {
      position: relative;
      background-color: white;
      display: block;
      margin: 0 auto;
      height: 150px;
      padding: 1em 5%; }
      body > wrapper > header logo a {
        display: block;
        position: relative;
        height: 100%; }
        body > wrapper > header logo a img {
          display: block;
          height: 100%;
          margin: 0 auto; }
      @media screen and (orientation: landscape) and (max-height: 400px) {
        body > wrapper > header logo {
          height: 100px; } }
  body > wrapper content {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin: 0 auto;
    position: relative; }
    body > wrapper content::after {
      clear: both;
      content: "";
      display: block; }
    body > wrapper content::after {
      clear: both;
      content: "";
      display: block; }
    body > wrapper content#main {
      text-align: justify; }
      body > wrapper content#main h3 {
        margin-bottom: 1em; }
      body > wrapper content#main #intro {
        text-align: center;
        margin-bottom: 2em; }
        body > wrapper content#main #intro p {
          font-size: 1.0625em;
          line-height: 1.6; }
    @media screen and (min-width: 1300px) {
      body > wrapper content {
        max-width: 1200px; } }
  body > wrapper > footer {
    margin-top: 2em; }
    body > wrapper > footer h6 {
      color: #72726c;
      margin-bottom: 1em;
      font-size: 1em; }
    body > wrapper > footer wrapper {
      background-color: #72726c;
      color: #dbdbda;
      padding: 1em 0;
      background-image: url("/_assets/images/logo-icon.svg");
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: auto 140px;
      background-opacity: 0.5; }
      body > wrapper > footer wrapper a {
        color: #f2e6d5; }
      body > wrapper > footer wrapper content.info p {
        font-size: 1.0625em;
        font-weight: 500;
        margin-bottom: .5em; }
        body > wrapper > footer wrapper content.info p a {
          font-weight: 800;
          font-color: #f2e6d5;
          font-size: 1.05882em; }
      body > wrapper > footer wrapper content.info .phone {
        color: white;
        font-size: 1.625em;
        font-weight: 800; }
      body > wrapper > footer wrapper content.social-buttons span {
        border-radius: 50%;
        background: #f2e6d5;
        padding: .5em;
        display: inline-block;
        line-height: 1;
        margin-right: .5em; }
        body > wrapper > footer wrapper content.social-buttons span:last-child {
          margin-right: 0; }
        body > wrapper > footer wrapper content.social-buttons span:hover {
          background: white; }
        body > wrapper > footer wrapper content.social-buttons span a {
          color: #72726c; }
      body > wrapper > footer wrapper content.post_footer {
        margin-top: 120px;
        text-align: center; }
        body > wrapper > footer wrapper content.post_footer p {
          font-size: 0.6875em; }
        body > wrapper > footer wrapper content.post_footer a {
          padding: 0 .5em;
          margin: 0 -2px 0 0;
          border-right: 1px solid #dbdbda; }
          body > wrapper > footer wrapper content.post_footer a.last {
            border-right: none;
            padding-right: 0;
            margin-right: 0; }
    @media screen and (min-width: 480px) {
      body > wrapper > footer {
        margin-top: 3em; }
        body > wrapper > footer wrapper content.post_footer {
          display: flex;
          justify-content: space-between; }
          body > wrapper > footer wrapper content.post_footer p {
            flex: 0 0 49%; } }

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

ul {
  list-style-type: disc;
  padding-left: 1.5em; }
  ul li {
    position: relative; }

ol {
  list-style-type: decimal;
  padding-left: 3em; }
  ol ol {
    list-style-type: lower-alpha;
    padding-left: 1.5em; }

dl, dt, dd {
  margin: 0; }

dt {
  font-weight: 600; }

figure {
  margin: 0; }

img,
picture {
  margin: 0;
  max-width: 100%;
  height: auto; }

[data-2x] {
  background-size: cover; }

iframe {
  display: block;
  width: 100%; }

.map-container {
  width: 100%; }
  .map-container:not(:last-child) {
    margin-bottom: 1.4em; }
  .map-container iframe {
    display: block;
    width: 100%;
    position: relative;
    pointer-events: none;
    height: auto;
    min-height: 300px; }
    .map-container iframe.clicked {
      pointer-events: auto; }
  .map-container p {
    font-size: 0.625em;
    font-style: italic;
    margin-top: 0.625em;
    color: #adadad; }

table {
  border-collapse: collapse;
  margin: 0.7em 0;
  table-layout: fixed;
  width: 100%; }
  table tr, table td, table th {
    vertical-align: middle; }
  table th {
    border-bottom: 1px solid #999999;
    font-weight: 600;
    padding: 0.7em 0;
    text-align: left; }
  table td {
    border-bottom: 1px solid #ccc;
    padding: 0.7em 0; }

body {
  color: #333;
  font-family: "Avenir W01", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center; }

h1 {
  font-family: "Avenir W01", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-weight: 400;
  color: #333;
  line-height: 1.2;
  margin: 0 0 0.46667em; }
  h1:first-child {
    margin-top: 0; }
  h1.heading {
    overflow: hidden; }
    h1.heading span {
      position: relative;
      display: inline-block; }
      h1.heading span:before, h1.heading span:after {
        content: "";
        position: absolute;
        border-bottom: 1px solid #b6b4b0;
        width: 100%;
        height: 1px;
        top: 50%; }
      h1.heading span:before {
        right: 100%;
        margin-right: 1em; }
      h1.heading span:after {
        left: 100%;
        margin-left: 1em; }

h2 {
  font-family: "Avenir W01", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-weight: 400;
  color: #333;
  line-height: 1.2;
  margin: 0 0 0.46667em; }
  h2:first-child {
    margin-top: 0; }
  h2.heading {
    overflow: hidden; }
    h2.heading span {
      position: relative;
      display: inline-block; }
      h2.heading span:before, h2.heading span:after {
        content: "";
        position: absolute;
        border-bottom: 1px solid #b6b4b0;
        width: 100%;
        height: 1px;
        top: 50%; }
      h2.heading span:before {
        right: 100%;
        margin-right: 1em; }
      h2.heading span:after {
        left: 100%;
        margin-left: 1em; }

h3 {
  font-family: "Avenir W01", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-weight: 400;
  color: #333;
  line-height: 1.2;
  margin: 0 0 0.46667em; }
  h3:first-child {
    margin-top: 0; }
  h3.heading {
    overflow: hidden; }
    h3.heading span {
      position: relative;
      display: inline-block; }
      h3.heading span:before, h3.heading span:after {
        content: "";
        position: absolute;
        border-bottom: 1px solid #b6b4b0;
        width: 100%;
        height: 1px;
        top: 50%; }
      h3.heading span:before {
        right: 100%;
        margin-right: 1em; }
      h3.heading span:after {
        left: 100%;
        margin-left: 1em; }

h4 {
  font-family: "Avenir W01", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-weight: 400;
  color: #333;
  line-height: 1.2;
  margin: 0 0 0.46667em; }
  h4:first-child {
    margin-top: 0; }
  h4.heading {
    overflow: hidden; }
    h4.heading span {
      position: relative;
      display: inline-block; }
      h4.heading span:before, h4.heading span:after {
        content: "";
        position: absolute;
        border-bottom: 1px solid #b6b4b0;
        width: 100%;
        height: 1px;
        top: 50%; }
      h4.heading span:before {
        right: 100%;
        margin-right: 1em; }
      h4.heading span:after {
        left: 100%;
        margin-left: 1em; }

h5 {
  font-family: "Avenir W01", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-weight: 400;
  color: #333;
  line-height: 1.2;
  margin: 0 0 0.46667em; }
  h5:first-child {
    margin-top: 0; }
  h5.heading {
    overflow: hidden; }
    h5.heading span {
      position: relative;
      display: inline-block; }
      h5.heading span:before, h5.heading span:after {
        content: "";
        position: absolute;
        border-bottom: 1px solid #b6b4b0;
        width: 100%;
        height: 1px;
        top: 50%; }
      h5.heading span:before {
        right: 100%;
        margin-right: 1em; }
      h5.heading span:after {
        left: 100%;
        margin-left: 1em; }

h6 {
  font-family: "Avenir W01", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-weight: 400;
  color: #333;
  line-height: 1.2;
  margin: 0 0 0.46667em; }
  h6:first-child {
    margin-top: 0; }
  h6.heading {
    overflow: hidden; }
    h6.heading span {
      position: relative;
      display: inline-block; }
      h6.heading span:before, h6.heading span:after {
        content: "";
        position: absolute;
        border-bottom: 1px solid #b6b4b0;
        width: 100%;
        height: 1px;
        top: 50%; }
      h6.heading span:before {
        right: 100%;
        margin-right: 1em; }
      h6.heading span:after {
        left: 100%;
        margin-left: 1em; }

h1 {
  font-size: 1.5625em;
  text-transform: uppercase; }
  h1.heading {
    max-width: 90%;
    margin: 0 auto; }

h2 {
  font-size: 1.5em;
  text-transform: uppercase; }
  h2.heading {
    margin: 0; }

h3 {
  font-size: 1.375em;
  font-weight: 800; }

h4 {
  font-size: 1.3125em; }

h5 {
  font-size: 1.25em;
  font-weight: 800; }

h6 {
  font-size: 1.125em; }

p, ul, ol {
  margin: 0 0 0.7em; }
  p:last-child, ul:last-child, ol:last-child {
    margin-bottom: 0; }

a {
  color: #a78f82;
  text-decoration: none;
  transition: color 150ms ease; }
  a:active, a:focus, a:hover {
    color: #b9a59b; }
  a:hover {
    text-decoration: underline; }

strong {
  font-weight: 800; }

em {
  font-style: italic; }

sub, sup {
  font-size: 75%;
  padding-left: 0.2em;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

pre {
  font-family: "Bitstream Vera Sans Mono", "Consolas", "Courier", monospace;
  white-space: pre-wrap;
  padding: 2em;
  border: 1px solid rgba(51, 51, 51, 0.5); }
  pre code {
    overflow-wrap: break-word; }

hr {
  border-bottom: 1px solid #ccc;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.4em 0; }

@font-face {
  font-family: 'socicon';
  src: url("/_assets/fonts/socicon-webfont.eot");
  src: url("/_assets/fonts/socicon-webfont.eot?#iefix") format("embedded-opentype"), url("/_assets/fonts/socicon-webfont.woff") format("woff"), url("/_assets/fonts/socicon-webfont.woff2") format("woff2"), url("/_assets/fonts/socicon-webfont.ttf") format("truetype"), url("/_assets/fonts/socicon-webfont.svg#sociconregular") format("svg");
  font-weight: normal;
  font-style: normal;
  text-transform: initial; }

.socicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'socicon';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased; }
  .socicon:empty {
    width: 1em; }

[data-icon]:before {
  font-family: 'socicon';
  content: attr(data-icon);
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.socicon-twitter:before {
  content: "a"; }

.socicon-facebook:before {
  content: "b"; }

.socicon-google:before {
  content: "c"; }

.socicon-pinterest:before {
  content: "d"; }

.socicon-foursquare:before {
  content: "e"; }

.socicon-yahoo:before {
  content: "f"; }

.socicon-skype:before {
  content: "g"; }

.socicon-yelp:before {
  content: "h"; }

.socicon-feedburner:before {
  content: "i"; }

.socicon-linkedin:before {
  content: "j"; }

.socicon-viadeo:before {
  content: "k"; }

.socicon-xing:before {
  content: "l"; }

.socicon-myspace:before {
  content: "m"; }

.socicon-soundcloud:before {
  content: "n"; }

.socicon-spotify:before {
  content: "o"; }

.socicon-grooveshark:before {
  content: "p"; }

.socicon-lastfm:before {
  content: "q"; }

.socicon-youtube:before {
  content: "r"; }

.socicon-vimeo:before {
  content: "s"; }

.socicon-dailymotion:before {
  content: "t"; }

.socicon-vine:before {
  content: "u"; }

.socicon-flickr:before {
  content: "v"; }

.socicon-500px:before {
  content: "w"; }

.socicon-instagram:before {
  content: "x"; }

.socicon-wordpress:before {
  content: "y"; }

.socicon-tumblr:before {
  content: "z"; }

.socicon-blogger:before {
  content: "A"; }

.socicon-technorati:before {
  content: "B"; }

.socicon-reddit:before {
  content: "C"; }

.socicon-dribbble:before {
  content: "D"; }

.socicon-stumbleupon:before {
  content: "E"; }

.socicon-digg:before {
  content: "F"; }

.socicon-envato:before {
  content: "G"; }

.socicon-behance:before {
  content: "H"; }

.socicon-delicious:before {
  content: "I"; }

.socicon-deviantart:before {
  content: "J"; }

.socicon-forrst:before {
  content: "K"; }

.socicon-playstore:before {
  content: "L"; }

.socicon-zerply:before {
  content: "M"; }

.socicon-wikipedia:before {
  content: "N"; }

.socicon-apple:before {
  content: "O"; }

.socicon-flattr:before {
  content: "P"; }

.socicon-github:before {
  content: "Q"; }

.socicon-chimein:before {
  content: "R"; }

.socicon-friendfeed:before {
  content: "S"; }

.socicon-newsvine:before {
  content: "T"; }

.socicon-identica:before {
  content: "U"; }

.socicon-bebo:before {
  content: "V"; }

.socicon-zynga:before {
  content: "W"; }

.socicon-steam:before {
  content: "X"; }

.socicon-xbox:before {
  content: "Y"; }

.socicon-windows:before {
  content: "Z"; }

.socicon-outlook:before {
  content: "1"; }

.socicon-coderwall:before {
  content: "2"; }

.socicon-tripadvisor:before {
  content: "3"; }

.socicon-appnet:before {
  content: "4"; }

.socicon-goodreads:before {
  content: "5"; }

.socicon-tripit:before {
  content: "6"; }

.socicon-lanyrd:before {
  content: "7"; }

.socicon-slideshare:before {
  content: "8"; }

.socicon-buffer:before {
  content: "9"; }

.socicon-rss:before {
  content: ","; }

.socicon-vkontakte:before {
  content: ""; }

.socicon-disqus:before {
  content: ":"; }

.socicon-houzz:before {
  content: "+"; }

.socicon-mail:before {
  content: "@"; }

.socicon-patreon:before {
  content: "="; }

.socicon-paypal:before {
  content: "-"; }

.socicon-playstation:before {
  content: "^"; }

.socicon-smugmug:before {
  content: "¨"; }

.socicon-swarm:before {
  content: "$"; }

.socicon-triplej:before {
  content: "*"; }

.socicon-yammer:before {
  content: "&"; }

.socicon-stackoverflow:before {
  content: "("; }

.socicon-drupal:before {
  content: "#"; }

.socicon-odnoklassniki:before {
  content: "."; }

body.active-modal #wrapper {
  overflow: hidden;
  max-height: 100%; }

.modal {
  display: none;
  color: white; }
  .modal h1 {
    color: white; }
  .modal h2 {
    color: white; }
  .modal h3 {
    color: white; }
  .modal h4 {
    color: white; }
  .modal h5 {
    color: white; }
  .modal h6 {
    color: white; }

.blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 10;
  padding: 2.8em 0.7em;
  background-color: rgba(99, 80, 70, 0.95);
  text-align: center; }
  .blocker:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.05em; }
  .blocker.behind {
    background-color: transparent; }
  .blocker .modal {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 20;
    width: 100%;
    max-width: 100vw;
    text-align: left; }
    .blocker .modal.alert {
      max-width: 900px;
      background-color: #a78f82;
      min-height: auto;
      text-align: center;
      border-radius: 6px;
      padding: 1.4em;
      border: 2px solid white; }
    .blocker .modal a.close-modal {
      position: fixed;
      top: 20px;
      right: 20px;
      display: block;
      width: 30px;
      height: 30px;
      text-indent: -9999px;
      z-index: 50;
      color: white;
      text-align: center; }
      .blocker .modal a.close-modal:before {
        content: "\f057";
        font-family: FontAwesome;
        text-indent: 0;
        font-size: 30px;
        line-height: 0.88;
        display: block;
        position: absolute;
        top: 0;
        left: 0.5px;
        width: 100%; }
      .blocker .modal a.close-modal:hover:before {
        color: #d3c7c1; }
  .blocker .modal-spinner {
    display: none;
    width: 64px;
    height: 64px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -32px;
    margin-top: -32px;
    border-radius: 8px; }
  @media screen and (min-width: 480px) {
    .blocker {
      padding: 2.8em; } }

banner {
  width: 100%;
  text-align: center;
  position: relative;
  margin-bottom: 3em; }
  banner div {
    position: relative;
    width: 100%; }
    banner div h1 {
      text-align: center; }
    banner div a.btn.cta {
      margin: 0 auto;
      font-size: 1.25em;
      max-width: 90%; }
  banner.img {
    height: auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: calc((65vh - 3em) - 150px); }
    banner.img div {
      position: absolute;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3); }
      banner.img div h1 {
        padding: .5em 1em;
        color: white; }
        banner.img div h1.heading {
          margin: 0 auto; }
          banner.img div h1.heading.has_cta {
            margin: 0 auto -0.5em; }
          banner.img div h1.heading span:before, banner.img div h1.heading.heading span:after {
            border-bottom: 1px solid white; }
      banner.img div a.btn.cta {
        bottom: -1em; }
    @media screen and (min-width: 768px) {
      banner.img {
        height: calc((90vh - 3em) - 190px); }
        banner.img > img {
          display: none; } }
    @media screen and (orientation: landscape) and (max-height: 400px) {
      banner.img {
        height: calc((100vh - 3em) - 100px); }
        banner.img h1 {
          font-size: 1.125em; } }

#mainNav {
  background-color: #a78f82;
  box-shadow: 0 2px 0 2px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
  display: none; }
  #mainNav ul {
    list-style-type: none;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    position: relative; }
    #mainNav ul li {
      display: inline-block;
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      margin-left: -5px;
      line-height: 1; }
      #mainNav ul li a {
        display: inline-block;
        color: white;
        padding: 0.75em 1em; }
      #mainNav ul li:last-child {
        border-right: none; }
      #mainNav ul li.has-children a:after {
        font-family: 'FontAwesome';
        content: "\f0d7";
        color: white;
        padding-left: .5em; }
      #mainNav ul li.has-children li a:after {
        content: "";
        padding: 0; }
      #mainNav ul li:hover a, #mainNav ul li.active a, #mainNav ul li.parent-active a,
      #mainNav ul li.has-children:hover a:after {
        text-decoration: none;
        color: #d3c7c0; }
      #mainNav ul li ul {
        position: absolute;
        width: 100%;
        display: none;
        background-color: rgba(255, 255, 255, 0.9);
        left: 0;
        top: 100%;
        text-align: left;
        border-top: 4px solid rgba(114, 114, 108, 0.5);
        padding-bottom: 0.5em; }
        #mainNav ul li ul li {
          font-size: .85em;
          display: block;
          margin-left: 0; }
          #mainNav ul li ul li a {
            font-weight: 600;
            color: #72726c !important;
            text-transform: none;
            padding: .6em 1em;
            display: block; }
            #mainNav ul li ul li a:hover {
              color: #a78f82 !important;
              border-left: 4px solid #a78f82; }
      #mainNav ul li:hover ul {
        display: block; }
  @media screen and (min-width: 769px) {
    #mainNav {
      display: block; } }

/* */
header .trigger {
  font-size: 30px;
  line-height: 1;
  position: absolute;
  right: 20px;
  top: 20px;
  display: block; }
  @media screen and (min-width: 769px) {
    header .trigger {
      display: none; } }

/* menu overlay nav */
#menu .wrap {
  color: white; }
  #menu .wrap logo {
    max-width: 200px;
    margin: 0 auto; }
  #menu .wrap nav {
    margin: 20px auto; }
    #menu .wrap nav ul {
      list-style-type: none;
      font-size: 1.125em;
      padding: 0;
      margin: 0;
      text-align: center; }
      #menu .wrap nav ul li {
        text-transform: uppercase;
        line-height: 1;
        position: relative; }
        #menu .wrap nav ul li:after {
          content: "";
          display: block;
          position: relative;
          width: 20%;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
          margin: 0 auto 10px;
          padding-bottom: 10px; }
        #menu .wrap nav ul li a {
          color: white;
          display: block;
          padding: 0.7em; }
          #menu .wrap nav ul li a:hover, #menu .wrap nav ul li a.active {
            color: rgba(255, 255, 255, 0.7);
            text-decoration: none; }
      #menu .wrap nav ul ul {
        font-size: 0.88889em; }
        #menu .wrap nav ul ul li {
          text-transform: none;
          border-bottom: none;
          padding: 0;
          margin: 0; }
          #menu .wrap nav ul ul li:after {
            display: none; }
          #menu .wrap nav ul ul li a {
            padding: 0.46667em;
            color: rgba(255, 255, 255, 0.5); }

.cat_menu {
  width: auto;
  margin: 2em auto;
  text-align: center; }
  .cat_menu select {
    color: #808080;
    border: 2px solid #a78f82;
    margin: 0 auto; }

/* */
.widget {
  padding: 1em 0;
  margin: 2em 0;
  background-color: #eee8e4; }
  .widget .content {
    overflow: hidden; }
  .widget ul.grid {
    list-style-type: none;
    margin: 0;
    padding: 1em 0 0;
    text-align: center; }
    .widget ul.grid li {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      display: inline-block;
      width: 32%;
      height: 180px;
      position: relative;
      margin-left: 1.5%;
      margin-bottom: 1em; }
      .widget ul.grid li.item-1 {
        clear: both;
        margin-left: 0; }
      .widget ul.grid li h6 {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0;
        font-size: 1.125em;
        font-weight: 500; }
    .widget ul.grid a li h6 {
      display: block;
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      padding: .25em .5em; }
    .widget ul.grid a:hover li h6 {
      background-color: rgba(167, 143, 130, 0.8);
      text-decoration: none; }
  .widget .flex {
    list-style-type: none;
    margin: 0;
    padding: 1em 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 1; }
    .widget .flex > * {
      position: relative;
      flex: 0 0 100%;
      margin: 0 auto 1em; }
      @media screen and (min-width: 480px) {
        .widget .flex > * {
          flex: 0 0 49%; } }
      @media screen and (min-width: 768px) {
        .widget .flex > * {
          flex: 0 0 32%; } }
      .widget .flex > * a {
        display: block;
        position: relative; }
        .widget .flex > * a img {
          width: 100%;
          height: auto;
          z-index: 2;
          position: relative;
          display: block; }
        .widget .flex > * a h6 {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          margin: 0;
          font-size: 1.125em;
          font-weight: 500;
          display: block;
          background-color: rgba(0, 0, 0, 0.3);
          color: white;
          padding: .25em .5em;
          z-index: 5; }
        .widget .flex > * a:hover h6 {
          background-color: rgba(167, 143, 130, 0.8);
          text-decoration: none; }
  .widget h2 {
    margin-top: 0; }

/** attorneys widget **/
.w_attorneys h2 span:before, .w_attorneys h2 span:after {
  border-bottom: 1px solid #b0ada8; }

.w_blog {
  margin: 0 auto 1em;
  background-color: transparent; }
  .w_blog content {
    padding-bottom: 1em; }
  .w_blog ul {
    list-style-type: none;
    margin: 1em 0 0;
    padding: 1em 0;
    border-bottom: 1px solid #b6b4b0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .w_blog ul li {
      flex: 0 0 100%;
      margin-bottom: 1em; }
      .w_blog ul li h5 {
        margin: 0 0 0.5em; }
      .w_blog ul li a {
        color: #a78f82; }
      .w_blog ul li p {
        font-size: 1.0625em;
        margin: 0 0 .5em; }
        .w_blog ul li p.post_info {
          color: #808080;
          font-size: 0.82353em;
          font-style: oblique;
          margin-top: 1em; }
          .w_blog ul li p.post_info a {
            font-weight: 800; }
      @media screen and (min-width: 600px) {
        .w_blog ul li {
          flex: 0 0 48%; } }
      @media screen and (min-width: 768px) {
        .w_blog ul li {
          flex: 0 0 45%; } }

/* */
h1.heading {
  margin: 1em auto; }

.process_indicator {
  background: url("/_assets/images/loading-anim.gif") no-repeat center top;
  height: 50px;
  width: 100%;
  text-align: left;
  display: inline-block;
  display: none;
  margin: 2em auto 1em; }

article {
  text-align: justify; }
  article, article .post_info {
    text-align: left; }

article {
  border-bottom: 1px solid #c8c6c4;
  padding: 0 0 2em; }
  article:not(:first-child) {
    padding-top: 2em; }
  article:last-child {
    padding-bottom: 0;
    border-bottom: 0; }
  article h2 {
    margin-bottom: .4em; }
    article h2 a {
      color: #333; }
      article h2 a:hover {
        color: #945c25;
        text-decoration: none; }

.post_info {
  color: #808080;
  font-style: oblique; }
  .post_info a {
    color: #a78f82;
    font-style: normal;
    font-weight: 800;
    text-decoration: underline; }
    .post_info a:hover {
      text-decoration: none; }

#ajax_container .more_wrap {
  width: 100%;
  position: relative;
  text-align: center; }
  #ajax_container .more_wrap a.btn {
    margin: 1em auto;
    font-size: 1.2em;
    font-weight: 500;
    cursor: pointer; }
    #ajax_container .more_wrap a.btn:hover {
      color: white;
      border-color: white;
      background-color: #a78f82; }

/* */
#contact content#main, #contact .submit_button {
  text-align: center; }

/* */
#sitemapList {
  text-align: center; }
  #sitemapList ul {
    display: inline-block; }
    #sitemapList ul li {
      text-transform: uppercase;
      font-weight: 600;
      border-bottom: 1px solid #ccc; }
      #sitemapList ul li a {
        padding: .5em;
        display: block;
        color: #72726c; }
      #sitemapList ul li ul {
        margin: 0 0 1em 0;
        display: block; }
        #sitemapList ul li ul li {
          text-transform: none;
          font-weight: 300;
          border-bottom: none; }
          #sitemapList ul li ul li a {
            color: #945c25; }
