/* *////////////////////////////////////////////////
// BLOG
///////////////////////////////////////////////*/

// Blog feed widget
//

// TODO: confirm if this style is necessary:
h1.heading {
	margin: 1em auto; }

.process_indicator {
	background: image('loading-anim.gif') no-repeat center top;
	height: 50px;
	width: 100%;
	text-align: left;
	display: inline-block;
	display: none;
	margin: 2em auto 1em; }


article {
	text-align: justify;
	@include headings(), .post_info {
		text-align: left; } }



article {
	border-bottom: 1px solid #c8c6c4;
	padding: 0 0 2em;
	&:not(:first-child) {
		padding-top: 2em; }
	&:last-child {
		padding-bottom: 0;
		border-bottom: 0; }
	h2 {
		margin-bottom: .4em;
		a {
			color: $base-color;
			&:hover {
				color: #945c25;
				text-decoration: none; } } } }

.post_info {
	color: #808080;
	font-style: oblique;
	a {
		color: $brand-color-1;
		font-style: normal;
		font-weight: 800;
		text-decoration: underline;
		&:hover {
			text-decoration: none; } } }

#ajax_container .more_wrap {
	width: 100%;
	position: relative;
	text-align: center;

	a.btn {
		margin: 1em auto;
		font-size: 1.2em;
		font-weight: 500;
		cursor: pointer;
		&:hover {
			color: white;
			border-color: white;
			background-color: $brand-color-1; } } }

/* *////////////////////////////////////////////////
// Contact
///////////////////////////////////////////////*/
#contact {
	content#main, .submit_button {
		text-align: center; } }

/* *////////////////////////////////////////////////
// Misc
///////////////////////////////////////////////*/
#sitemapList {
	text-align: center;
	ul {
		display: inline-block;
		li {
			text-transform: uppercase;
			font-weight: 600;
			border-bottom: 1px solid #ccc;
			a {
				padding: .5em;
				display: block;
				color: $brand-color-2; }
			ul {
				margin: 0 0 1em 0;
				display: block;
				li {
					text-transform: none;
					font-weight: 300;
					border-bottom: none;
					a {
						color: #945c25; } } } } } }


