// socifon
@font-face {
	font-family: 'socicon';
	src: font('socicon-webfont.eot');
	src: font('socicon-webfont.eot?#iefix') format('embedded-opentype'), font('socicon-webfont.woff') format('woff'), font('socicon-webfont.woff2') format('woff2'), font('socicon-webfont.ttf') format('truetype'), font('socicon-webfont.svg#sociconregular') format('svg');
	font-weight: normal;
	font-style: normal;
	text-transform: initial; }

.socicon {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'socicon';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;

	&:empty {
		width: 1em; } }


// Use the following CSS code if you want to use data attributes for inserting your icons
[data-icon]:before {
	font-family: 'socicon';
	content: attr(data-icon);
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display:inline-block {} }

// Use the following CSS code if you want to have a class per icon

// Instead of a list of all class selectors,
// you can use the generic selector below, but it's slower:
// [class*="icon-"]

.socicon-twitter:before {
	content: "a"; }
.socicon-facebook:before {
	content: "b"; }
.socicon-google:before {
	content: "c"; }
.socicon-pinterest:before {
	content: "d"; }
.socicon-foursquare:before {
	content: "e"; }
.socicon-yahoo:before {
	content: "f"; }
.socicon-skype:before {
	content: "g"; }
.socicon-yelp:before {
	content: "h"; }
.socicon-feedburner:before {
	content: "i"; }
.socicon-linkedin:before {
	content: "j"; }
.socicon-viadeo:before {
	content: "k"; }
.socicon-xing:before {
	content: "l"; }
.socicon-myspace:before {
	content: "m"; }
.socicon-soundcloud:before {
	content: "n"; }
.socicon-spotify:before {
	content: "o"; }
.socicon-grooveshark:before {
	content: "p"; }
.socicon-lastfm:before {
	content: "q"; }
.socicon-youtube:before {
	content: "r"; }
.socicon-vimeo:before {
	content: "s"; }
.socicon-dailymotion:before {
	content: "t"; }
.socicon-vine:before {
	content: "u"; }
.socicon-flickr:before {
	content: "v"; }
.socicon-500px:before {
	content: "w"; }
.socicon-instagram:before {
	content: "x"; }
.socicon-wordpress:before {
	content: "y"; }
.socicon-tumblr:before {
	content: "z"; }
.socicon-blogger:before {
	content: "A"; }
.socicon-technorati:before {
	content: "B"; }
.socicon-reddit:before {
	content: "C"; }
.socicon-dribbble:before {
	content: "D"; }
.socicon-stumbleupon:before {
	content: "E"; }
.socicon-digg:before {
	content: "F"; }
.socicon-envato:before {
	content: "G"; }
.socicon-behance:before {
	content: "H"; }
.socicon-delicious:before {
	content: "I"; }
.socicon-deviantart:before {
	content: "J"; }
.socicon-forrst:before {
	content: "K"; }
.socicon-playstore:before {
	content: "L"; }
.socicon-zerply:before {
	content: "M"; }
.socicon-wikipedia:before {
	content: "N"; }
.socicon-apple:before {
	content: "O"; }
.socicon-flattr:before {
	content: "P"; }
.socicon-github:before {
	content: "Q"; }
.socicon-chimein:before {
	content: "R"; }
.socicon-friendfeed:before {
	content: "S"; }
.socicon-newsvine:before {
	content: "T"; }
.socicon-identica:before {
	content: "U"; }
.socicon-bebo:before {
	content: "V"; }
.socicon-zynga:before {
	content: "W"; }
.socicon-steam:before {
	content: "X"; }
.socicon-xbox:before {
	content: "Y"; }
.socicon-windows:before {
	content: "Z"; }
.socicon-outlook:before {
	content: "1"; }
.socicon-coderwall:before {
	content: "2"; }
.socicon-tripadvisor:before {
	content: "3"; }
.socicon-appnet:before {
	content: "4"; }
.socicon-goodreads:before {
	content: "5"; }
.socicon-tripit:before {
	content: "6"; }
.socicon-lanyrd:before {
	content: "7"; }
.socicon-slideshare:before {
	content: "8"; }
.socicon-buffer:before {
	content: "9"; }
.socicon-rss:before {
	content: ","; }
.socicon-vkontakte:before {
	content: ""; }
.socicon-disqus:before {
	content: ":"; }
.socicon-houzz:before {
	content: "+"; }
.socicon-mail:before {
	content: "@"; }
.socicon-patreon:before {
	content: "="; }
.socicon-paypal:before {
	content: "-"; }
.socicon-playstation:before {
	content: "^"; }
.socicon-smugmug:before {
	content: "¨"; }
.socicon-swarm:before {
	content: "$"; }
.socicon-triplej:before {
	content: "*"; }
.socicon-yammer:before {
	content: "&"; }
.socicon-stackoverflow:before {
	content: "("; }
.socicon-drupal:before {
	content: "#"; }
.socicon-odnoklassniki:before {
	content: "."; }
