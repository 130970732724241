body {
	color: $base-font-color;
	font-family: $base-font-family;
	font-size: $base-font-size;
	font-weight: $base-font-weight;
	line-height: $base-line-height;
	text-align: center; }

@include headings() {
	font-family: $heading-font-family;
	font-weight: $base-font-weight;
	color: $base-color;
	line-height: $heading-line-height;
	margin: 0 0 $tiny-spacing;
	&:first-child {
		margin-top: 0; }

	&.heading {
		overflow: hidden;
		span {
			position: relative;
			display: inline-block;
			&:before, &:after {
				content: "";
				position: absolute;
				border-bottom: 1px solid #b6b4b0;
				width: 100%;
				height: 1px;
				top: 50%; }
			&:before {
				right: 100%;
				margin-right: 1em; }
			&:after {
				left: 100%;
				margin-left: 1em; } } } }

h1 {
	font-size: $h1-font-size;
	text-transform: uppercase;
	&.heading {
		max-width: $max-width;
		margin: 0 auto; } }

h2 {
	font-size: $h2-font-size;
	text-transform: uppercase;
	&.heading {
		margin: 0; } }

h3 {
	font-size: $h3-font-size;
	font-weight: 800;
	// &.title
	// 	font-family: $serif-font-family
	// 	font-style: italic
	// 	color: $brand-color-2
	// 	position: relative
	// 	border-top: 1px solid tint($base-color, 80%)
	// 	line-height: 1
	// 	margin-bottom: 40px
	// 	margin-top: 0.75em
	// 	span
	// 		padding-right: .5em
	// 		position: relative
	// 		float: left
	// 		margin-top: -0.6em
	// 		display: inline-block
 }	// 		background: white

h4 {
	font-size: $h4-font-size; }
h5 {
	font-size: $h5-font-size;
	font-weight: 800; }
h6 {
	font-size: $h6-font-size; }

p, ul, ol {
	margin: 0 0 $small-spacing;
	&:last-child {
		margin-bottom: 0; } }

a {
	color: $action-color;
	text-decoration: none;
	transition: color $base-duration $base-timing;
	&:active,
	&:focus,
	&:hover {
		color: tint($action-color, 20%); }
	&:hover {
		text-decoration: underline; } }

strong {
	font-weight: 800; }

em {
	font-style: italic;
 }	// font-style: oblique

sub, sup {
	font-size: 75%;
	padding-left: 0.2em;
	line-height: 0;
	position: relative; }

sup {
	top: -0.5em; }

sub {
	bottom: -0.25em; }

pre {
	font-family: $mono-font-family;
	white-space: pre-wrap;
	padding: 2em;
	border: 1px solid rgba($base-color, 0.5);
	code {
		overflow-wrap: break-word; } }

hr {
	border-bottom: $base-border;
	border-left: 0;
	border-right: 0;
	border-top: 0;
	margin: $base-spacing 0; }


