banner {
	width: 100%;
	text-align: center;
	position: relative;
	margin-bottom: 3em;
	div {
		position: relative;
		width: 100%;
		h1 {
			text-align: center; }
		a.btn.cta {
			margin: 0 auto;
			font-size: em(20);
			max-width: 90%; } }

	// when image background
	&.img {
		height: auto;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		// full-window accounting for header height
		height: calc((65vh - 3em) - 150px);

		div {
			position: absolute;
			bottom: 0;
			background-color: rgba(black, 0.3);
			h1 {
				padding: .5em 1em;
				color: white;
				&.heading {
					margin: 0 auto;
					&.has_cta {
						margin: 0 auto -0.5em; }
					span:before, &.heading span:after {
						border-bottom: 1px solid white; } } }
			a.btn.cta {
				bottom: -1em; } }


		// full-window accounting for header height
		@media screen and (min-width: $medium-screen) {
			// background-color: tint($brand-color-2, 30%)
			height: calc((90vh - 3em) - 190px);
			> img {
				display: none; } }

		@media screen and (orientation: landscape) and (max-height: 400px) {
			height: calc((100vh - 3em) - 100px);
			h1 {
				font-size: em(18); } } } }




