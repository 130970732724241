// wrapping container
@mixin outer-container($local-max-width: $max-width) {
	@include clearfix;
	max-width: $local-max-width;
	margin: {
		left: auto;
		right: auto; } }

// centering
@mixin pure-center() {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); }

// flex columns
@mixin columns() {
	display: flex;
	// default assumes 2 columns
	> * {
		width: 50%;
		&:first-child {
			margin-right: $small-spacing; }
		&:last-child {
			margin-left: $small-spacing; } } }
