// Navigation Styles
#mainNav {
	background-color: $brand-color-1;
	box-shadow: 0 2px 0 2px rgba(black, 0.2);
	position: relative;
	z-index: 1;
	display: none;
	ul {
		list-style-type: none;
		text-transform: uppercase;
		margin: 0;
		padding: 0;
		position: relative;
		li {
			display: inline-block;
			border-right: 1px solid rgba(white, 0.2);
			margin-left: -5px;
			line-height: 1;
			a {
				display: inline-block;
				color: white;
				padding: em(12) em(16); }

			&:last-child {
				border-right: none; }

			&.has-children {
				a:after {
					font-family: 'FontAwesome';
					content: "\f0d7";
					color: white;
					padding-left: .5em; }
				li a:after {
					content: "";
					padding: 0; } }

			&:hover a, &.active a, &.parent-active a,
			&.has-children:hover a:after {
				text-decoration: none;
				color: #d3c7c0; }

			ul {
				position: absolute;
				width: 100%;
				display: none;
				background-color: rgba(white, 0.9);
				left: 0;
				top: 100%;
				text-align: left;
				border-top: 4px solid rgba($brand-color-2, 0.5);
				padding-bottom: 0.5em;

				li {
					font-size: .85em;
					display: block;
					margin-left: 0;
					a {
						font-weight: 600;
						color: $brand-color-2 !important;
						text-transform: none;
						padding: .6em 1em;
						display: block;
						&:hover {
							color: $brand-color-1 !important;
							border-left: 4px solid #a78f82; } } } }
			&:hover ul {
				display: block; } } }

	@media screen and (min-width: 769px) {
		display: block; } }


/* *////////////////////////////////////////////////
// Mobile Navigation
///////////////////////////////////////////////*/
header .trigger {
	font-size: 30px;
	line-height: 1;
	position: absolute;
	right: 20px;
	top: 20px;
	display: block;

	@media screen and (min-width: 769px) {
		display: none; } }

/* menu overlay nav */
#menu .wrap {
	color: white;
	logo {
		max-width: 200px;
		margin: 0 auto;
		a {
			img {} } }

	nav {
		margin: 20px auto;
		// border-top: 1px solid white
		ul {
			list-style-type: none;
			font-size: em(18);
			padding: 0;
			margin: 0;
			text-align: center;
			li {
				text-transform: uppercase;
				line-height: 1;
				position: relative;
				&:after {
					content: "";
					display: block;
					position: relative;
					width: 20%;
					border-bottom: 1px solid rgba(white, 0.5);
					margin: 0 auto 10px;
					padding-bottom: 10px; }
				a {
					color: white;
					display: block;
					padding: $small-spacing;
					&:hover, &.active {
						color: rgba(white, 0.7);
						text-decoration: none; } } }
			ul {
				font-size: em(16, 18);
				li {
					text-transform: none;
					border-bottom: none;
					padding: 0;
					margin: 0;
					&:after {
						display: none; }
					a {
						padding: $tiny-spacing;
						color: rgba(white, 0.5); } } } } } }


// category cavigation
.cat_menu {
	width: auto;
	margin: 2em auto;
	text-align: center;
	select {
		color: #808080;
		border: 2px solid $brand-color-1;
		margin: 0 auto; } }


