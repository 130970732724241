figure {
	margin: 0; }

img,
picture {
	margin: 0;
	max-width: 100%;
	height: auto; }

[data-2x] {
		background-size: cover; }

iframe {
	display: block;
	width: 100%; }

.map-container {
	width: 100%;
	&:not(:last-child) {
		margin-bottom: $base-spacing; }
	iframe {
		display: block;
		width: 100%;
		position: relative; // IE needs a position other than static
		pointer-events: none;
		height: auto;
		min-height: 300px;
		&.clicked {
			pointer-events: auto; } }
	p {
		font-size: em(10);
		font-style: italic;
		margin-top: em(10);
		color: tint($base-color, 60%); } }

