/* *////////////////////////////////////////////////
// WIDGETS
///////////////////////////////////////////////*/
.widget {
	padding: 1em 0;
	margin: 2em 0;
	background-color: #eee8e4;

	.content {
		overflow: hidden; }

	ul.grid {
		list-style-type: none;
		margin: 0;
		padding: 1em 0 0;
		text-align: center;

		li {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			display: inline-block;
			width: 32%;
			height: 180px;
			position: relative;
			margin-left: 1.5%;
			margin-bottom: 1em;
			&.item-1 {
				clear: both;
				margin-left: 0; }

			h6 {
				width: 100%;
				position: absolute;
				bottom: 0;
				left: 0;
				margin: 0;
				font-size: em(18);
				font-weight: 500; } }

		a li h6 {
			display: block;
			background-color: rgba(black, 0.3);
			color: white;
			padding: .25em .5em; }

		a:hover li h6 {
				background-color: rgba(167, 143, 130, 0.8);
				text-decoration: none; } }


	.flex {
		list-style-type: none;
		margin: 0;
		padding: 1em 0 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		z-index: 1;
		> * {
			position: relative;
			flex: 0 0 100%;
			margin: 0 auto 1em;
			@media screen and (min-width: $small-screen) {
				flex: 0 0 49%; }
			@media screen and (min-width: $medium-screen) {
				flex: 0 0 32%; }


			a {
				display: block;
				position: relative;

				img {
					width: 100%;
					height: auto;
					z-index: 2;
					position: relative;
					display: block; }

				h6 {
					width: 100%;
					position: absolute;
					bottom: 0;
					left: 0;
					margin: 0;
					font-size: em(18);
					font-weight: 500;
					display: block;
					background-color: rgba(black, 0.3);
					color: white;
					padding: .25em .5em;
					z-index: 5; }

				&:hover {
					h6 {
						background-color: rgba(167, 143, 130, 0.8);
						text-decoration: none; } } } } }





	h2 {
		margin-top: 0; } }

/** attorneys widget **/
.w_attorneys {
	h2 span:before, h2 span:after {
		border-bottom: 1px solid #b0ada8; } }

.w_blog {
	margin: 0 auto 1em;
	background-color: transparent;
	content {
		padding-bottom: 1em; }

	ul {
		list-style-type: none;
		margin: 1em 0 0;
		padding: 1em 0;
		border-bottom: 1px solid #b6b4b0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		li {
			flex: 0 0 100%;
			margin-bottom: 1em;
			h5 {
				margin: 0 0 0.5em; }
			a {
				color: $brand-color-1; }
			p {
				font-size: em(17);
				margin: 0 0 .5em;
				&.post_info {
					color: #808080;
					font-size: em(14, 17);
					font-style: oblique;
					margin-top: 1em;
					a {
						font-weight: 800; } } }

			@media screen and (min-width: 600px) {
				flex: 0 0 48%; }

			@media screen and (min-width: $medium-screen) {
				flex: 0 0 45%; } } } }

